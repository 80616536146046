body {
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*overflow-y: scroll;*/
    background: linear-gradient(253.21deg, #ffffff 1.76%, #c9d6d8 55.2%);
}

/*.container {*/
/*    padding: 0;*/
/*    position: relative;*/
/*    width: 100%;*/
/*}*/

/*.circleContainer {*/
/*    position: absolute;*/
/*    right: 10px;*/
/*}*/
